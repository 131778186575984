$(function(){

	$('.slider').slick({
		dots: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000,
		speed: 1000,
		fade: true,
	});

	$('.featured-latest-jobs-slider').slick({
		dots: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000,
		speed: 1000,
		vertical: true,
	    slidesToShow: 2,
	    slidesToScroll: 2,
	    verticalSwiping: true,
	});

	$(document).on('click', '.js-expand-block-2', function(e){
		e.preventDefault();
		$('.expanded-block-2').toggleClass('open');
	});

	$(document).on('click', '.js-close-expanded-block-2', function(e){
		e.stopPropagation();
		$('.expanded-block-2').removeClass('open');
	});

	$(document).on('click', '.js-expand-block-3', function(e){
		e.preventDefault();
		$('.expanded-block-3').toggleClass('open');
	});

	$(document).on('click', '.js-close-expanded-block-3', function(e){
		e.stopPropagation();
		$('.expanded-block-3').removeClass('open');
	});

	$(document).on('click', '.js-expand-block-4', function(e){
		e.preventDefault();
		$('.expanded-block-4').toggleClass('open');
	});

	$(document).on('click', '.js-close-expanded-block-4', function(e){
		e.stopPropagation();
		$('.expanded-block-4').removeClass('open');
	});

	$(document).on('click', '.js-mobile-header-trigger', function(e){
		e.preventDefault();
		$('.mobile-navigation').slideToggle();
	});

	$(document).on('click', '.js-open-mobile-sub', function(e){
		e.preventDefault();
		$(this).next('.mobile-navigation-sub').slideToggle();
	});

});
