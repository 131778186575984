/**
 * Ajax Form Submit Event Delegation
 *
 * All forms with an [data-request-validation] attribute will run through this.
 *
 * Options can be added as data-attributes, e.g. <form data-request-validation data-field-error-messages="true" data-form-message="false">
 *
 *

data-form-message - Prepend form with error/success message - default true
data-field-error-classes - Adds is-erroneous class to each form field which didn't pass validation - default true
data-field-error-messages - Adds specific error message after each form field - default false
 */

$(document).on('ajaxError', '[data-request-validation]', function(event, context, statusText, jqXHR) {
    var $form = $(this),
        prependMessage = (Boolean) ($form.attr('data-form-message') || true),
        addErrorClasses = (Boolean) ($form.attr('data-field-error-classes') || true),
        addErrorMessagesToFields = (Boolean) ($form.attr('data-field-error-messages') || true);

    if (prependMessage === true)
    {
        // Get error message
        var errorMessage = (jqXHR.responseJSON && jqXHR.responseJSON.X_OCTOBER_ERROR_FIELDS)
            ? 'Please review the fields below and try again.'
            : 'There has been a problem submitting the form, please check you have completed it correctly and try again.';

        // Remove old ones
        $form.find('.form-error-message').remove();

        // Prepend error message
        $form.prepend('<div class="form-error-message alert alert-warning">' + errorMessage + '</div>');
    }

    if (addErrorClasses === true || addErrorMessagesToFields === true)
    {
        var errorFields = (jqXHR.responseJSON && jqXHR.responseJSON.X_OCTOBER_ERROR_FIELDS);

        // Remove fields with error class
        $form.find(':input.is-erroneous').removeClass('is-erroneous');

        // Remove field error message
        $form.find('.input-error-message').remove();


        if (errorFields)
        {
            $.each(errorFields, function(field, message) {
                var $field = $form.find(':input[name=' + field + ']');

                if (addErrorClasses === true) $field.addClass('is-erroneous');
                if (addErrorMessagesToFields === true) $field.after('<div class="input-error-message">' + message[0] + '</div>');
            });
        }
    }

    // This prevents the alert from showing.
    return false;
});

$(document).on('ajaxSuccess', '[data-request-validation]', function(event, context, statusText, jqXHR) {
    var $form = $(this),
        prependMessage = (Boolean) ($form.attr('data-form-message') || true);

    // Remove prepended error message
    $form.find('.form-error-message').remove();

    // Remove fields with error class
    $form.find(':input.is-erroneous').removeClass('is-erroneous');

    // Remove field error message
    $form.find('.input-error-message').remove();

    // Prepend success message
    if (prependMessage === true)
    {
        $form.prepend('<div class="form-error-message alert alert-success">Thanks, your message has been submitted successfully</div>');
    }

    // Clear off inputs
    $form.find(':input').val('');
});
